import React, { useEffect, useState } from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../../core/Components/Link';

const MarketingConsentComponent = ({ fields }) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };

    if (typeof window !== 'undefined') {
      setScreenSize(getCurrentDimension());
      window.addEventListener('resize', updateDimension);

      return () => {
        window.removeEventListener('resize', updateDimension);
      };
    }
  }, []);

  function getCurrentDimension() {
    if (typeof window !== 'undefined') {
      return {
        width: window.innerWidth,
      };
    }
    return { width: 1366 };
  }

  return (
    <div
      className="marketing-consent-container"
      style={{
        backgroundImage:
          screenSize.width <= 1024
            ? 'none'
            : `url(${
                fields.DesktopImage.value.src
                  ? fields.DesktopImage.value.src
                  : ''
              })`,
      }}
    >
      <div className="marketing-consent l-container">
        <div className="text-block-container">
          <h2 className="description-text">
            <Text field={fields.Text} />
          </h2>
          <Link field={fields.getStarted} className="cta-button tertiary" />
        </div>
        <div className="image-block-container">
          <Image field={fields.MobileImage} />
        </div>
      </div>
    </div>
  );
};

export default MarketingConsentComponent;
